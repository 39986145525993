<template>
  <div class="content-wrapper">
    <div class="expense-statistics main-content">

      <div class="page-header">
        <h1>Order Report</h1>
      </div>

      <div class="chart-wrapper">
        <BarChart :options="chartOptions" />
      </div>

      <div class="profit-loss-table" v-if="report">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Order Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, month) in report">
              <td>{{ month }}</td>
              <td>{{ item}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'OrderReport',
  components: {
    BarChart,
  },
  data(){
    return {
      report: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted(){
    this.loadOrderReport();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadOrderReport(){
      const loginInfo = Common.getLoginInfo();
      try{
        const report = await Sales.loadOrderReport(this.apiUrl, loginInfo);
        this.report = report;

        const month = Object.keys(report);
        const data = [];

        for(let i = 0; i < month.length; i++){
          const key = Object.keys(report)[i];
          const totalAmount = report[key];
          data.push(totalAmount);
        }

        const datasets = [
          {
            label: 'Order Statistics',
            backgroundColor: ['#3F88C5'],
            data: data,
          }
        ];

        for(let i = 0; i < datasets[0].data.length; i++){
          if (datasets[0].data[i] > 0) {
            datasets[0].backgroundColor[i] = '#3F88C5'
          } else {
            datasets[0].backgroundColor[i] = '#FF5E5B'
          }
        }

        const chartData = {
          labels: month,
          datasets: datasets
        };

        this.setCurrentChartData(chartData);

      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentChartData']),
  },
  computed: mapState({
   currentChartData: state => state.chart.currentChartData,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.profit-loss-table{
  margin-top: 50px;
}
</style>
